.content-card {
    height: 450px;
}

.content-card .card-text {
    height: 120px;
    overflow: hidden;
    /*将对象作为弹性伸缩盒子模型显示*/
    display: -webkit-box;
    /*设置子元素排列方式*/
    -webkit-box-orient: vertical;
    /*设置显示的行数，多出的部分会显示为...*/
    -webkit-line-clamp: 5;
}

.content-card img {
    height: 200px;
}

@media (max-width: 440px) {
    .content-card {
        height: 430px;
    }

    .content-card img {
        height: 175px;
    }
}