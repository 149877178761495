.headline {
  height: 550px;
}

.headline-poster {
  height: 322px;
}

.abstract {
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
}

@media (max-width: 440px) {
  .headline {
    height: 390px;
  }
  .headline-poster {
    height: 175px;
  }
}
