.search-input {
  border: 0px;
  border-bottom: 2px solid #515151;
  height: 25px;
  outline: none;
}

.search-btn:hover {
  background-color: rgb(156, 156, 161);
  border: white;
}

.navbar-fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 1030;
}

.logo {
  height: 35px;
}

.logo-title {
  font-weight: bold;
}